<template>
  <div class="flex-grow-1 pb-12">
    <v-card max-width="360" flat>
      <v-card-text>
        <h4 class="font-weight-black mb-2 text-center text-sm-left" :class="$vuetify.breakpoint.xs ? 'text-h4' : 'text-h3'">Sign Up</h4>
        <v-sheet class="mx-auto" :max-width="$vuetify.breakpoint.xs ? '250' : '100%'">
          <p class="body-1 mb-5 text-center text-sm-left">
            Enter your details below to begin your search.
          </p>
        </v-sheet>

        <v-alert v-if="status.error" type="error" border="left" text>
          {{ status.error }}
        </v-alert>

        <v-form ref="loginForm" @submit.prevent="validateSignup()">
          <v-select
            v-model="data.role"
            :items="roles"
            :rules="[rules.required]"
            item-text="text"
            item-value="value"
            label="I am…"
            height="48"
            outlined
          ></v-select>

          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="data.firstName"
                :rules="[rules.required]"
                label="First Name"
                height="48"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="data.lastName"
                :rules="[rules.required]"
                label="Last Name"
                height="48"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-text-field
            v-model="data.email"
            :rules="[rules.required, rules.email]"
            label="Email Address"
            height="48"
            outlined
            dense
          ></v-text-field>

          <div v-if="data.role === 'jobseeker'">
            <div class="mb-5 mt-1">
              <vue-google-autocomplete
                id="school-address"
                ref="toAddress"
                classname="address-field"
                v-on:placechanged="getAddress"
                placeholder="Your Suburb"
                :fields="['address_components', 'geometry']"
                types="(regions)"
                country="au"
              >
              </vue-google-autocomplete>
            </div>

            <div v-if="data.address" class="mb-5">
              <v-row dense>
                <v-col cols="12" sm="4">
                  <v-text-field
                    :value="data.address.locality || ''"
                    label="Suburb"
                    hide-details
                    readonly
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    :value="data.address.administrative_area_level_1 || ''"
                    label="State"
                    hide-details
                    readonly
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    :value="data.address.postal_code || ''"
                    label="Postal Code"
                    hide-details
                    readonly
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <!-- POSITION -->
            <v-autocomplete
              :multiple="positions.length == 1? false : true"
              :items="orderBy(positions, 'order', 'asc')"
              v-model="profile.position"
              :rules="[rules.required]"
              item-value="name"
              label="Position"
              item-text="name"
              deletable-chips
              tabindex="6"
              small-chips
              full-width
              outlined
              chips
              dense
            >
            </v-autocomplete>

            <!-- SKILL SETS -->
            <SkillSetsField :value.sync="profile.skillset" label="Skill Sets" dense multiple required :tabindex="1"/>

            <v-select
              v-model="profile.teachingLvl"
              label="Years Of Experience"
              :rules="[rules.required]"
              :items="teachingLvl"
              class="mb-1"
              outlined
              dense
            ></v-select>
          </div>

          <v-text-field
            v-model="data.password"
            @keydown.enter="validateSignin()"
            :rules="[rules.required, rules.password]"
            label="Create Password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            height="48"
            outlined
            dense
          ></v-text-field>
        </v-form>

        <div class="mb-3">
          By registering you are agreeing to our <a href="https://www.teacherfinder.com.au/terms-and-conditions/" target="_blank">Terms and conditions</a> and our <a href="https://www.teacherfinder.com.au/privacy-policy/" target="_blank">Privacy policy</a>
        </div>

        <v-btn
          @click="validateSignup()"
          :loading="status.signingUp"
          color="accent gradient"
          class="rounded-lg"
          block
          >
            Create Account
          </v-btn>

        <!-- <div class="mt-5 mb-n2 caption">Sign up using your existing accounts</div> -->

        <!-- <v-row>
          <v-col>
            <v-btn
              @click="social('facebook')"
              :disabled="status.signingIn"
              color="#3B5999"
              class="mt-2 white--text"
              single-line
              block
            >
              <v-avatar size="16" class="mr-2">
                <v-img
                  :src="require('@/assets/facebook.svg')"
                ></v-img>
              </v-avatar>
              <span class="text-none ls-0 heading-font">Facebook</span>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              @click="social('google')"
              :disabled="status.signingIn"
              color="white"
              class="mt-2"
              block
            >
              <v-avatar size="16" class="mr-2">
                <v-img
                  :src="require('@/assets/google-icon.svg')"
                ></v-img>
              </v-avatar>
              <span class="text-none ls-0 heading-font">Google</span>
            </v-btn>
          </v-col>
        </v-row> -->
        
        <v-row align="center" class="mt-3" no-gutters>
          <v-col cols="8">
            <div class="black--text body-1 caption">
              Already have an existing account?
            </div>
          </v-col>
          <v-col cols="4">
            <div class="text-right">
              <router-link
                :to="{ name: 'Login' }"
                class="black--text body-1 caption font-weight-bold"
              >
                Sign In
              </router-link>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import rules from "@/rules"
import { orderBy } from 'lodash'
import { mapState, mapActions } from "vuex"

import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  data() {
    return {
      rules,
      orderBy,
      showPassword: false,
      roles: [
        {
          text: "I’m Looking For Work",
          value: 'jobseeker'
        },
        {
          text: "I’m Looking To Employ",
          value: 'employer'
        },
      ],
      teachingLvl: [
        'Graduate',
        '2-4 years',
        '4-10 years',
        '10+ years'
      ],
    }
  },

  components: {
    VueGoogleAutocomplete
  },

  computed: {
    ...mapState({
      data: (state) => state.user.data,
      user: (state) => state.user.user,
      status: (state) => state.user.status,
      positions: state => state.position.data,
      profile: (state) => state.profile.profile,
    }),
  },

  watch: {
    'user': function (user) {
      if (user) {
        this.$router.push({ name: 'MyAccount' })
      }
    },
  },

  methods: {
    ...mapActions("user", ["signup", "socialSignin"]),
    ...mapActions("settings", ["getSettings"]),

    validateSignup() {
      if (this.$refs.loginForm.validate()) {
        this.signup()
      }
    },

    social(type) {
      if (this.data.role ) {
        Promise.all([this.socialSignin(type)])
        .then(() => this.$router.push({name: 'MyAccount'}))
      } 
      else this.$store.dispatch('showError', 'Please select a role.')
    },

    getAddress(addressData) {
      this.$store.commit('user/updateDataObject', { address: addressData })
      this.$refs.toAddress.$refs.autocomplete.value = ''
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getSettings()
    this.$store.dispatch('position/getPositions')
  }
}
</script>

<style lang="scss">
.v-main {
  &.withBackground {
    .v-main__wrap {
      background: url("/images/bg1.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
    }
  }
}
</style>